<script>
import i18n from '@/../engine/framework/modules/i18n';
import environment from '@/environment';

export default {
  name: 'Ayuda',
  beforeRouteEnter() {
    const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
    const canShowHelp = !authUser.isPatient;

    if (canShowHelp) {
      const locale = i18n.locale;
      let helpUrl = '';

      switch (locale) {
        case 'ca':
        case 'es':
          helpUrl = environment.helpLinks?.es ?? 'https://ayuda.docline.es';
          break;

        case 'ar':
        case 'en':
        case 'pl':
        case 'pt':
        default:
          helpUrl = environment.helpLinks?.en ?? 'https://help.docline.es';
          break;
      }
      window.open(helpUrl);
    }
  },
};
</script>
